const linkScroll = (function($) {
	const init = function() {
		$('a[href^="#"]').click(function(event) {
			event.preventDefault();
			// const theHash = this.hash;
			if (this.hash !== '') {
				event.preventDefault();
				const theHash = this.hash;
				$('html, body').animate(
					{ scrollTop: $(theHash).offset().top },
					500
				);
			}
		});
	};
	return {
		init,
	};
})(jQuery);

export default linkScroll;
