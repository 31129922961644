const searchForm = ((window, $) => {
	const init = () => {
		// const $html = $('html');
		const mediaQuery = window.matchMedia('(min-width: 1025px)');
		const topMenu = $('.top-menu');
		if (!mediaQuery.matches) {
			// topMenu.hide();
			$('.menu-primary').append(
				`<li class="nav-item-top-menu">${topMenu.html()}</li>`
			);
		}
		const $body = $('body');
		const searchFormButton = $('[rel="open-button"]');
		const modalWindow = $('[rel="modal-search"]');

		searchFormButton.on('click', () => {
			$('.search-form-input').focus(function() {
				$(this).val('');
			});
			$body.toggleClass('blur compensate-for-scrollbar');
			// $html.toggleClass('hide-scrollbar');
			modalWindow.toggleClass('fade-in');
		});
		modalWindow.on('click', (e) => {
			if ($(e.target).is('input')) {
				return false;
			}
			modalWindow.removeClass('fade-in');
			$body.removeClass('blur compensate-for-scrollbar');
			// $html.removeClass('hide-scrollbar');
		});
	};

	return {
		init,
	};
})(window, jQuery);

export default searchForm;
